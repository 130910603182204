import { useRef } from "react";
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import PageContainer from "../../components/page-container";
import { useUser } from "../../hooks/use-user";
import { event } from "nextjs-google-analytics";
import { usePlans } from "../../hooks/use-plans";
import { logger } from "../../utils/logger";
import { useMixpanel } from "../../hooks/use-mixpanel";
import { trackTiktok } from "../../utils/track-tiktok";
import { useLocalStorage } from "../../hooks/use-local-storage";
import PricingSwitch from "../../components/pricing-switch";
import ProgressLoader from "../../components/progress-loader";
import { siteConfig } from "../../configuration/config";
import { useSessionStorage } from "../../hooks/use-session-storage";
import { GetStaticProps } from "next";
import { localeFromLangCode } from "../../utils/locale-from-header";
import { LandingLocale } from "../../localization/landing";
import SenjaTestimonialsSmall from "../../components/senja-testimonials-small";

export const billingSelectionKey = "billing-selection";
export const promotionCodeIdKey = "promotion-code-id";

interface PageProps {
  l: LandingLocale;
}

export const getStaticProps: GetStaticProps<PageProps> = async (props) => {
  const locale = localeFromLangCode(props.params?.lang as string);

  return {
    props: { ...locale },
  };
};

export default function Billing(props: PageProps) {
  const router = useRouter();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const {
    base64sessionUser,
    systemUser,
    sessionUser,
    subscriptionIsActive,
    userDidLoad,
    pendingInvitation,
  } = useUser(false, true);

  const promotionCodeId = useSessionStorage<string>(promotionCodeIdKey);

  const { plans, invalidCheckoutLink, plansDidLoad } = usePlans(
    true,
    false,
    promotionCodeId.storageData
  );
  const preselectedProductId = useLocalStorage<string>(billingSelectionKey);
  const { trackMixpanelEvent } = useMixpanel();
  const didTrack = useRef(false);

  useEffect(() => {
    if (!siteConfig.isDefault) {
      //router.push("/authentication");
    }
  }, [router]);

  useEffect(() => {
    if (!sessionUser?.email || isRedirecting) {
      return;
    }

    if (pendingInvitation) {
      setIsRedirecting(true);

      const organizationQuery = pendingInvitation.organizationId
        ? `&organization=${pendingInvitation.organizationId}`
        : "";

      const partnerQuery = pendingInvitation.partner
        ? `&partner=${pendingInvitation.partner}`
        : "";

      router.push(
        `/invitation?email=${sessionUser.email}${organizationQuery}${partnerQuery}`
      );
      return;
    }
  }, [pendingInvitation, sessionUser, isRedirecting, router]);

  useEffect(() => {
    if (!userDidLoad) {
      return;
    }

    if (!base64sessionUser) {
      router.push("/authentication");
    } else if (subscriptionIsActive) {
      router.push("/app");
      promotionCodeId?.clearStorage();
    } else {
      if (!didTrack.current) {
        didTrack.current = true;
        const user = sessionUser as { id: string; email: string } | undefined;
        logger.info("Track InitiateCheckout");

        trackTiktok("InitiateCheckout", { email: user?.email });
        trackMixpanelEvent("Initiate Checkout", { id: user?.id });
      }
    }
  }, [
    promotionCodeId,
    userDidLoad,
    subscriptionIsActive,
    sessionUser,
    base64sessionUser,
    router,
    trackMixpanelEvent,
  ]);

  // Forward user to the selected plan if the user has already selected a plan on frontpage
  useEffect(() => {
    if (
      plansDidLoad &&
      plans &&
      !preselectedProductId.isLoadingStorage &&
      base64sessionUser
    ) {
      let product = plans.products.find(
        (product) => product.id === preselectedProductId.storageData
      );

      preselectedProductId.clearStorage();

      // if (plans.products.length === 1) {
      //   product = plans.products[0];
      // }

      if (product) {
        setIsRedirecting(true);
        window.location.href = product.checkoutLink;
      }
    }
  }, [plans, plansDidLoad, preselectedProductId, base64sessionUser]);

  useEffect(() => {
    event("sign_up");
  }, []);

  if (!siteConfig.isDefault) {
    return (
      <Box textAlign={"center"} my={12}>
        <Box>This account does not have an active subscription.</Box>
        <Box mb={2}>
          Could it be possible that you have logged in with a different email?
        </Box>
        <Link
          fontWeight={"bold"}
          color="brand.secondary.main"
          onClick={() => signOut({ callbackUrl: "/" })}
        >
          Log out
        </Link>
      </Box>
    );
  }

  if (!userDidLoad || !plansDidLoad || isRedirecting || !base64sessionUser) {
    return (
      <Box minH="600px" my={"150px"}>
        <ProgressLoader
          step={4}
          intervalTime={350}
          label1="Just a second..."
          label2="...loading plans..."
          label3="...almost there!"
        />
      </Box>
    );
  }

  return (
    <Box position={"relative"}>
      <PageContainer>
        <Box textAlign={"center"} mb={5}>
          <Heading size="md" mt={5} mb={4}>
            Select a plan
          </Heading>

          {systemUser && !subscriptionIsActive ? (
            <>
              <Text color="brand.red.400">
                Your subscription has expired, please subscribe to a new plan.{" "}
              </Text>
              <Text fontSize={"xs"} mt={1} fontStyle="italic" mb={8}>
                If you believe this is a mistake then do not hesitate to{" "}
                <Link
                  color={"brand.secondary.main"}
                  href="mailto:support@talkio.ai"
                >
                  contact us
                </Link>
                .
              </Text>
            </>
          ) : (
            <>
              <Text
                id="billing-text"
                maxW={"600px"}
                margin="0 auto"
                mb={12}
                display="none"
              >
                Unlock your language potential with Talkio. Your journey to
                mastery starts now.
              </Text>
              <Flex id="billing-senja" justifyContent={"center"} mt={6} mb="10">
                <SenjaTestimonialsSmall />
              </Flex>
            </>
          )}
        </Box>

        {plans &&
          (invalidCheckoutLink ? (
            <Text
              color="brand.red.500"
              textAlign={"center"}
              fontSize="md"
              mt={5}
              mb={12}
            >
              Unable to read your profile info <br /> Please contact customer
              support at support@talkio.ai
            </Text>
          ) : (
            <PricingSwitch
              l={props.l}
              promotionCodeId={promotionCodeId.storageData}
              products={plans.products}
              buttonCallback={({ checkoutLink }) => {
                window.location.href = checkoutLink;
              }}
            />
          ))}

        {sessionUser && (
          <Flex justifyContent={"center"} mt={5}>
            <Link
              fontSize="sm"
              fontWeight={"bold"}
              color="gray.400"
              onClick={() => signOut({ callbackUrl: "/" })}
            >
              Log out
            </Link>
          </Flex>
        )}
      </PageContainer>
    </Box>
  );
}
