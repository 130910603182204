import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  List,
  ListItem,
  Stack,
  Text,
  Flex,
} from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";

export interface ButtonCallbackProps {
  productId: string;
  checkoutLink: string;
}

interface Props {
  title: string;
  description: string;
  price: string;
  discountPrice?: string;
  discountPercentage?: number;
  features: string[];
  submitText: string;
  submitLink: string;
  colorScheme: "black" | "white";
  paymentProviders?: string[];
  productId: string;
  buttonCallback: (props: ButtonCallbackProps) => void;
}

export const PricingCard = (props: Props) => {
  return (
    <Box
      id={`pricing-card-${props.productId.toLowerCase()}`}
      bg={props.colorScheme === "black" ? "gray.800" : "white"}
      color={props.colorScheme === "black" ? "white" : "gray.800"}
      borderWidth="1px"
      borderRadius="2xl"
      boxShadow="sm"
      px={{ base: "6", md: "8" }}
      py="8"
      mx={2}
      width="full"
      maxW="md"
      mb={3}
      position="relative"
    >
      <Flex
        display={props.discountPrice ? "flex" : "none"}
        w="full"
        justifyContent={"center"}
        position="absolute"
        top={0}
        left={0}
        className="discount-teaser"
      >
        <Flex
          mt={"-12px"}
          bg={"brand.green.400"}
          color="#fff"
          py={1}
          px={4}
          borderRadius="md"
          fontSize="xs"
        >
          <Text>
            Limited Time Offer!{" "}
            <strong>Save {props.discountPercentage}%</strong>
          </Text>
        </Flex>
      </Flex>

      <Stack spacing={{ base: "10", md: "10" }} textAlign="center">
        <Stack align="center">
          <Text textStyle={{ base: "sm", md: "md" }} fontWeight="semibold">
            {props.title}
          </Text>

          <Flex
            align={"center"}
            display={props.discountPrice ? "flex" : "none"}
            className="discount-price"
          >
            <Text
              ml={"-65px"}
              mr={3}
              fontSize="lg"
              textDecor={"line-through"}
              color="gray.500"
            >
              {props.price}
            </Text>
            <Heading color="brand.green.400" size={{ base: "sm", md: "md" }}>
              {props.discountPrice}
            </Heading>
          </Flex>

          <Heading
            display={!props.discountPrice ? "block" : "none"}
            size={{ base: "sm", md: "md" }}
            className="regular-price"
          >
            {props.price}
          </Heading>

          <Text fontSize="sm">{props.description}</Text>
        </Stack>
        <List spacing="4">
          {props.features.map((feature, i) => (
            <ListItem
              key={feature}
              className={`pricing-card-feature pricing-card-feature-${i}`}
            >
              <HStack spacing="4">
                <Icon as={FiCheck} color="accent" boxSize="6" />
                <Text fontSize="sm" textAlign="left">
                  {feature}
                </Text>
              </HStack>
            </ListItem>
          ))}
        </List>
        <Flex flexDir="column">
          <Button
            onClick={() =>
              props.buttonCallback({
                productId: props.productId,
                checkoutLink: props.submitLink,
              })
            }
            size="md"
            colorScheme="brand.green"
          >
            {props.submitText}
          </Button>

          {props.paymentProviders && (
            <Text fontSize="2xs" mt={3} color="gray.500">
              {props.paymentProviders.join(", ")} and more
            </Text>
          )}
        </Flex>
      </Stack>
    </Box>
  );
};
